import React, { useRef, useEffect, useContext } from "react";
import gsap from "gsap";

import { PrismicImage } from "types/prismicImage";
import { PrismicRichText } from "types/prismicRichText";

import { Container } from "components/container/Container";
import { RichText } from "components/contentful/rich-text/RichText";
import { Image } from "components/contentful/image/Image";

import { UIContext } from "context/ui";

import { scroll } from "constants/Scroll";

import s from "./MediaTextFeatures.scss";
import { VideoItem } from "components/video-item/VideoItem";

interface IProps {
  image?: PrismicImage;
  title?: string;
  text?: PrismicRichText;
  features?: Array<IFeatureProps> | null;
  target?: string;
  newProp: boolean;
  lessSpacing: boolean;
}

interface IFeatureProps {
  icon?: PrismicImage;
  title?: string;
  description?: PrismicRichText;
}

export const MediaTextFeatures = ({
  mediaTextFeatures,
  target,
  titleInside = false,
  allFeaturesLeft = false,
  noIcons = false,
  limitedTitleWidth = false,
  imgNoMarginTop = false,
  radiusTopRight = false,
  noRadius = false,
  hasParallax = true,
  addMarginTop = false,
  swapTitleAndContent = false,
  lessSpacing = false
}: any) => {
  const { text, image, title } = mediaTextFeatures;
  const innerTitle = titleInside;
  const allFeaturesAlignedLeft = allFeaturesLeft;
  const marginTop = addMarginTop;
  const imageLeft = mediaTextFeatures.alignImageLeft;
  const limitTitleWidth = limitedTitleWidth;
  const video = mediaTextFeatures.video || null;
  const parallax = hasParallax;
  const videoUrl = mediaTextFeatures.videoUrl || null;
  const swapTitleContent = swapTitleAndContent;
  const content = mediaTextFeatures.items.map((feature: any, index: number) => {
    if (!noIcons) {
      return (
        <li
          key={index}
          className={`${s.mediaTextFeatures__feature} ${lessSpacing &&
            s.mediaTextFeatures__feature__lessSpacing} | js-t-list`}
        >
          <figure
            className={`${s.mediaTextFeatures__feature__icon} | js-l-img`}
          >
            <img
              src={feature.icon?.file.url}
              alt={feature.icon?.description}
              width={feature.icon?.file.details.image.width}
              height={feature.icon?.file.details.image.height}
              loading="lazy"
            />
          </figure>
          <div
            className={`${s.mediaTextFeatures__feature__description} | js-l-info`}
          >
            <h2>{feature.title}</h2>
            <RichText richText={feature?.description} />
          </div>
        </li>
      );
    } else {
      return (
        <li
          key={index}
          className={`${s.mediaTextFeatures__feature} ${lessSpacing &&
            s.mediaTextFeatures__feature__lessSpacing} | js-t-list`}
        >
          <div
            className={`${s.mediaTextFeatures__feature__noIconDescription} | js-l-info`}
          >
            <h2>{feature.title}</h2>
            <RichText richText={feature?.description} />
          </div>
        </li>
      );
    }
  });

  const { isMobile, isDesktop } = useContext(UIContext);
  const featuresLeft = content?.splice(0, 2);
  const featuresRight = content;

  const featuresRef = useRef<HTMLDivElement>(null);
  const imgWrapRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLDivElement>(null);
  let tl: GSAPTimeline;
  let tl2: GSAPTimeline;

  useEffect(() => {
    if (imgWrapRef.current && featuresRef.current) {
      const listItems = featuresRef.current.querySelectorAll(".js-t-list");
      const listItemImages = featuresRef.current.querySelectorAll(".js-l-img");
      const listItemDescriptions = featuresRef.current.querySelectorAll(
        ".js-l-info"
      );

      // fade in desktop
      if (isDesktop) {
        setTimeout(() => {
          tl = gsap.timeline({
            scrollTrigger: {
              scroller: scroll.container,
              trigger: imgWrapRef.current,
              start: "top 50%"
            }
          });
          tl.addLabel("start")
            .set(listItemImages, { scale: 0.7 })
            //.to(imgWrapRef.current.firstChild, { duration: 1, ease: 'Power3.easeInOut', borderBottomLeftRadius: 100 })=
            .to(
              listItemImages,
              {
                duration: 0.6,
                ease: "Power3.easeInOut",
                stagger: 0.3,
                autoAlpha: 1,
                scale: 1
              },
              "start+=0.3"
            )
            .to(
              listItemDescriptions,
              {
                duration: 0.6,
                stagger: 0.3,
                ease: "Power3.easeInOut",
                autoAlpha: 1
              },
              "start+=0.4"
            );
        }, 1500);
      }

      // fade in mobile
      if (isMobile) {
        setTimeout(() => {
          listItems.forEach(item => {
            const image = item.querySelector(".js-l-img");
            const description = item.querySelector(".js-l-info");

            if (image && description) {
              gsap.fromTo(
                image,
                { autoAlpha: 0, scale: 0.7 },
                {
                  scrollTrigger: image,
                  duration: 0.6,
                  ease: "Power3.easeInOut",
                  autoAlpha: 1,
                  scale: 1
                }
              );

              gsap.fromTo(
                description,
                { autoAlpha: 0 },
                {
                  scrollTrigger: description,
                  duration: 0.6,
                  ease: "Power3.easeInOut",
                  autoAlpha: 1
                }
              );
            }
          });
        }, 1500);
      }

      // parallax
      if (isDesktop && imgWrapRef.current && imgRef.current && parallax) {
        gsap.set(imgRef.current.querySelector("figure"), { scale: 1.3 });
        gsap.fromTo(
          imgRef.current,
          {
            y: 0
          },
          {
            y: 100,
            scrollTrigger: {
              scroller: scroll.container,
              trigger: imgWrapRef.current,
              scrub: true
            }
          }
        );
      }
    }
  }, [isMobile, isDesktop]);

  useEffect((): any => {
    return () => tl && tl.kill();
  }, [isDesktop]);

  return (
    <div className={s("mediaTextFeatures", { marginTop })} data-target={target}>
      <Container>
        {!innerTitle && (
          <div className={s.mediaTextFeatures__row__top}>
            <div className={s.mediaTextFeatures__row__top__col}>
              {title && !limitTitleWidth && (
                <h2 className={`${s.mediaTextFeatures__title} | js-t-title`}>
                  {title}
                </h2>
              )}
              {title && limitTitleWidth && (
                <h2
                  className={`${s.mediaTextFeatures__limitedTitle} | js-t-title`}
                >
                  {title}
                </h2>
              )}
            </div>
          </div>
        )}

        <div
          ref={featuresRef}
          className={s("mediaTextFeatures__row__bottom", {
            imageLeft,
            radiusTopRight,
            noRadius
          })}
        >
          <div className={s.mediaTextFeatures__content}>
            <div
              className={s("mediaTextFeatures__content__title_text", {
                swapTitleContent
              })}
            >
              {text && (
                <div
                  className={`${s.mediaTextFeatures__content__text} | js-t-text`}
                >
                  <RichText richText={text} />
                </div>
              )}

              {innerTitle && title && (
                <div className={s.mediaTextFeatures__row__top}>
                  {title && (
                    <h2
                      className={`${s.mediaTextFeatures__innerTitle} | js-t-title`}
                    >
                      {title}
                    </h2>
                  )}
                </div>
              )}
            </div>

            {!allFeaturesAlignedLeft && (
              <ul className={s.mediaTextFeatures__featuresContainer}>
                {featuresLeft}
              </ul>
            )}

            {allFeaturesAlignedLeft && (
              <ul className={s.mediaTextFeatures__featuresContainer}>
                {featuresLeft}
                {featuresRight}
              </ul>
            )}
          </div>

          {image && (
            <div className={s.mediaTextFeatures__img}>
              <div
                ref={imgWrapRef}
                className={s.mediaTextFeatures__img__rightFigure}
              >
                <div ref={imgRef}>
                  <Image image={image} srcSetMobile={true} />
                </div>
              </div>
              {!allFeaturesAlignedLeft && (
                <ul
                  className={s("mediaTextFeatures__featuresContainer", "right")}
                >
                  {featuresRight}
                </ul>
              )}
            </div>
          )}

          {!image && (video || videoUrl) && (
            <div className={s.mediaTextFeatures__img}>
              <div
                ref={imgWrapRef}
                className={s.mediaTextFeatures__img__rightFigure}
              >
                <div ref={imgRef}>
                  <VideoItem
                    item={{
                      video: mediaTextFeatures.video,
                      videoUrl: mediaTextFeatures?.videoUrl,
                      showPlayButton: false,
                      description: mediaTextFeatures.video?.description,
                      autoplay: true
                    }}
                    autoplay={true}
                    loop={true}
                  />
                </div>
              </div>
              {!allFeaturesAlignedLeft && (
                <ul
                  className={s("mediaTextFeatures__featuresContainer", "right")}
                >
                  {featuresRight}
                </ul>
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
